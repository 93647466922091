
.dashboard {
    text-align: center;
    margin-top: 50px;
  }
  
  .dashboard h1 {
    color: #007bff;
    font-size: 36px;
  }
  
  .dashboard p {
    font-size: 18px;
    color: #555;
  }