.App {
  text-align: center;
}

.logo {
  text-align: center;
}

.form-header {
  background-color: #00539F; /* Blue color from ClaimLion Law website */
  padding: 10px;
  text-align: center;
  width: 100%;  
  align-items: center;
  justify-content: flex-start;
}