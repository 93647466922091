.auth-form {
    width: 100%;
    max-width: 360px;
    padding: 20px;
    margin: 100px auto;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }
  
  .auth-form form {
    display: flex;
    flex-direction: column;
  }
  
  .auth-form label {
    margin-bottom: 5px;
    color: #333;
  }
  
  .auth-form input {
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .auth-form button {
    padding: 10px;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .auth-form button:hover {
    background-color: #0056b3;
  }
  