/* NavBar.css */

.navbar {
    background-color: #007bff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }
  
  .navbar-brand {
    font-size: 24px;
    font-weight: bold;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navbar-links li {
    margin-right: 20px;
  }
  
  .navbar-links li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
  }
  
  .navbar-links li a:hover {
    text-decoration: underline;
  }
  