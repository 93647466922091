/* Base styling */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Custom Form container */
.sar-form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 1280px;
  margin: 20px auto;
}

/* Form specific styling */
.form-container {
  background-color: #f7f7f7; /* Light grey background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 70%;
  margin-right: 20px;
}

/* Queue container styling */
.queue-container {
  background-color: #f7f7f7; /* Light grey background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
  max-height: 80vh;
  overflow-y: auto;
}

/* Table styling for queue */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table, th, td {
  border: 1px solid #ccc;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #007bff;
  color: white;
}

td button {
  margin-right: 10px;
  padding: 5px 10px;
}

td button:last-child {
  margin-right: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sar-form-container {
    flex-direction: column;
    align-items: center;
  }

  .form-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .queue-container {
    width: 100%;
    margin-bottom: 20px;
  }
}

/* Error message and nudge effect for validation */
.Mui-error {
  border-color: #f44336 !important; /* Red border for error */
  animation: nudge 0.3s ease-in-out;
}

@keyframes nudge {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}

/* Styling overrides to match brand colors */
label.MuiFormLabel-root {
  color: #333; /* Ensure label text is dark */
}

input.MuiInputBase-input {
  color: #333; /* Ensure input text is dark */
}

.MuiFormLabel-root.Mui-focused {
  color: #007bff !important; /* Blue label on focus */
}

.MuiInput-underline:after {
  border-bottom-color: #007bff !important; /* Blue underline on focus */
}

/* Custom button styles */
button.MuiButton-containedPrimary {
  background-color: #007bff;
}

button.MuiButton-containedPrimary:hover {
  background-color: #0056b3;
}

button.MuiButton-containedSecondary {
  background-color: #f44336;
}

button.MuiButton-containedSecondary:hover {
  background-color: #d32f2f;
}

.selectLender {
  text-align:left;
}
.lenderLabel {
  background: #f7f7f7;
}

.additional-container {
  text-align: left;  
}

.additional-container span {
  padding-left: 0px;
}